<template>
  <div class="view pa24">
    <div>
      <el-tabs v-model="memberType" @tab-click="fnQueryClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane
          :label="name"
          :name="id"
          v-for="(name, id) in levelData"
          :key="id"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column
          prop="phone"
          align="center"
          label="登陆账号"
          show-overflow-tooltip
        />
        <el-table-column
          show-overflow-tooltip
          prop="nickName"
          align="center"
          label="会员昵称"
        />
        <el-table-column
          show-overflow-tooltip
          prop="levelName"
          align="center"
          label="会员等级"
        >
        </el-table-column>
        <el-table-column
          prop="endDate"
          align="center"
          show-overflow-tooltip
          label="到期时间"
        >
        </el-table-column>
        <el-table-column prop="pointNow" align="center" label="积分">
          <template slot-scope="scops">
            {{ scops.row.pointNow | setNullValue("0") }}
          </template>
        </el-table-column>
        <el-table-column prop="pointTotal" align="center" label="积累积分">
          <template slot-scope="scops">
            {{ scops.row.pointNow | setNullValue("0") }}
          </template>
        </el-table-column>
        <el-table-column prop="inviteCode" align="center" label="邀请码" />
        <el-table-column prop="inviteName" align="center" label="邀请人" />
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch
              class="mr10"
              v-if="scope.row.status == 0 || scope.row.status == 1"
              v-model="scope.row.status"
              :active-value="0"
              :inactive-value="1"
              @change="setStatus($event, scope.row)"
            >
            </el-switch>
            <div v-else>
              {{ scope.row.status | getStatus }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
          <template slot-scope="scope">
            <!-- v-if="scope.row.status == 2 || scope.row.status == 3" -->
            <el-button
              v-if="scope.row.status == 2 || scope.row.status == 3"
              class="mr10"
              type="text"
              @click="showApplyFun(scope.row)"
              >审核</el-button
            >
            <el-button
              class="mr10"
              type="text"
              @click="showAddCodeFun(scope.row)"
              >增加积分</el-button
            >
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="增加会员积分"
      :visible.sync="showAddCode"
      :destroy-on-close="true"
      width="500px"
      center
    >
      <el-form
        :model="addCodeForm"
        :rules="addCodeRules"
        ref="addCodeForm"
        label-width="78px"
      >
        <el-form-item label="增加积分" prop="code">
          <el-input
            type="number"
            placeholder="请输入积分"
            v-model="addCodeForm.pointNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="增加原因" prop="note">
          <el-input
            type="textarea"
            :rows="5"
            :min="1"
            placeholder="请输入增加原因"
            v-model="addCodeForm.pointRecordName"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddCode = false">取 消</el-button>
        <el-button type="primary" @click="addPointRecord">增 加</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="会员审核"
      :visible.sync="showApplyModel"
      :destroy-on-close="true"
      width="300px"
      center
    >
      <el-form label-width="78px">
        <el-form-item label="会员等级" prop="code">
          {{ ApplyData.levelName }}
        </el-form-item>
        <el-form-item label="会员昵称" prop="note">
          {{ ApplyData.nickName }}
        </el-form-item>
        <el-form-item label="到期时间" prop="note">
          {{ ApplyData.endDate }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="applyFun(0)">通过</el-button>
        <el-button @click="applyFun(3)">不通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  selectAllMemberLink,
  selectAllMemberLevel,
  addPointRecord,
  updateLink,
} from "@/api/member";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
  },
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      showAddCode: false,
      tableData: [],
      memberType: "",
      userInfo: this.$store.state.loginRoot.userInfo,
      userId: "",
      levelData: {},
      addCodeRules: {
        pointNum: {
          required: true,
          targgir: "blur",
          message: "请输入积分数量",
        },
      },
      addCodeForm: {
        pointNum: 0,
        pointRecordName: "",
        pointId: "",
        userId: "",
        pointRecordType: 0,
      },
      showApplyModel: false,
      ApplyData: {
        levelName: "",
        nickName: "",
        endDate: "",
      },
      statusData: {
        levelId: "",
        userId: "",
        linkId: "",
        status: "",
      },
    };
  },
  filters: {
    setNullValue(val, tips) {
      if (val) {
        return val;
      } else {
        return tips;
      }
    },
    getStatus(status) {
      //替换状态显示内容
      let statusData = { 0: "启用", 1: "禁用" };
      return statusData[status];
    },
    getEndDate(time) {
      if (time) {
        return new Date(time).toLocaleString().replace("//ig", "-");
      } else {
        return "永久";
      }
    },
    getArea(area) {
      //替换地区显示内容
      if (area) {
        return area.replace(/,/g, "/");
      } else {
        return "-";
      }
    },
  },
  async created() {
    await this.getInitData();
    this.selectAllMemberLink();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    showAddCodeFun(data) {
      //添加
      this.showAddCode = true;
      this.addCodeForm = {
        pointNum: 0,
        pointRecordName: "",
        pointId: data.pointId,
        userId: data.userId,
        pointRecordType: 0,
      };
    },
    /**@method 切换会员 */
    addPointRecord() {
      this.$refs.addCodeForm.validate((valid) => {
        if (valid) {
          let params = { ...this.addCodeForm };
          addPointRecord(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "积分增加完成",
                type: "success",
              });
              this.showAddCode = true;
              this.selectAllMemberLink();
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          });
        }
      });
    },
    /**@method 获取初始化信息 用户列表 等级列表*/
    getInitData() {
      return Promise.all([
        new Promise(async (open) => {
          let params = {
            pageSize: 50,
            pageNum: 1,
            companyId: this.userInfo.companyId,
            // companyId: 2,
          };
          const result = await selectAllMemberLevel(params); //等级列表
          if (result.code === 200) {
            for (var row of result.data.pageInfo.list) {
              this.$set(this.levelData, row.levelId, row.levelName);
            }
            //-console.log(this.levelData);
          } else {
            this.levelData = {};
          }
          open();
        }),
      ]);
    },
    /**@method 获取会员列表 */
    async selectAllMemberLink(payload) {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        companyId: this.userInfo.companyId,
        // companyId: 2,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await selectAllMemberLink(params);
        this.loading = false;

        const { data } = result;
        let tableData = [];
        for (let row of data.pageInfo.list) {
          tableData.push(row);
        }
        this.tableData = tableData;
        this.total = data.pageInfo.total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.selectAllMemberLink();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectAllMemberLink();
    },
    /**@method 切换文章发布状态
     * @param {Boolean} e - switch值
     * @param {String} id - id
     */
    async switchChange(e, id, type) {
      let data = {
        dynamicId: id,
        isDrafts: e ? 0 : 1,
      };
      if (type) {
        data = {
          dynamicId: id,
          isTop: e ? 1 : 0,
        };
        updateNewsDynamicTop(data)
          .then((res) => {
            if (e) {
              this.$message.success("置顶成功！");
            } else {
              this.$message.success("取消置顶！");
            }
            this.selectAllMemberLink();
          })
          .catch((err) => {
            if (err.code == 201) {
              //-console.log(err);
              this.$message.error(err.message);
            }
          });
      } else {
        await updateNewsDynamicState(data);
        await this.selectAllMemberLink();
      }
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {};
      if (this.memberType != "all") {
        data.levelId = this.memberType;
      }
      this.selectAllMemberLink(data);
    },
    /**@method 审核
     * @param {Object} val - 当前点击行的值
     */
    showApplyFun(row) {
      //显示审核模态框
      this.showApplyModel = true;
      this.ApplyData = {
        levelName: row.levelName,
        nickName: row.nickName,
        endDate: row.endDate,
      };
      this.statusData = {
        levelId: row.levelId,
        userId: row.userId,
        linkId: row.linkId,
        status: 0,
      };
    },
    applyFun(status) {
      //审核
      this.statusData.status = status;
      updateLink(this.statusData)
        .then((res) => {
          if (res.code === 200) {
            this.selectAllMemberLin();
          } else {
            this.$message(res.message);
          }
        })
        .catch((err) => {
          if (err.code === 201) {
            this.$message(res.message);
          }
        });
    },
    setStatus(status, row) {
      //修改状态
      this.statusData = {
        levelId: row.levelId,
        userId: row.userId,
        linkId: row.linkId,
        status: status,
      };
      updateLink(this.statusData)
        .then((res) => {
          if (res.code === 200) {
            this.selectAllMemberLin();
          } else {
            this.$message(res.message);
          }
        })
        .catch((err) => {
          if (err.code === 201) {
            this.$message(res.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>